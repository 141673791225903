import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule, Provider } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import * as Sentry from '@sentry/angular-ivy';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { SharedModule } from '@shared/shared.module';
import { TitlesModule } from './titles/titles.module';
import { AuthModule } from '@auth/auth.module';

import { AuthRoutingModule } from '@auth/routing/auth.routing.module';
import { TitlesRoutingModule } from './titles/routing/titles-routing.module';
import { NoticesRoutingModule } from './notices/notices-routing.module';

import { IntercomModule } from 'ng-intercom';

import { CachControlInterceptor } from './core/interceptors/cash-control.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { LoggingInterceptor } from './core/interceptors/logging.interceptor';
import { AuthorisationInterceptor } from './core/interceptors/authorisation.interceptor';

import { environment } from '@env/environment';
import { appRoutes } from './app-routing';
import { NoticesModule } from './notices/notices.module';
import { LeasesModule } from './leases/leases.module';
import { AppErrorHandler } from './app.error-handler';
import { SystemInfoModule } from './system-info/system-info.module';

const providersList: Provider[] = [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachControlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorisationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
];

if (environment.sentryData.isTracingEnabled) {
    providersList.push({ provide: Sentry.TraceService, deps: [Router] });
    providersList.push({
        provide: APP_INITIALIZER,
        useFactory: () => () => void 0,
        deps: [Sentry.TraceService],
        multi: true,
    });
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        TitlesModule,
        NoticesModule,
        AuthModule,
        SharedModule,
        CoreModule,
        RouterModule.forRoot(appRoutes),
        TitlesRoutingModule,
        NoticesRoutingModule,
        AuthRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        IntercomModule.forRoot(environment.intercomModule),
        LeasesModule,
        SystemInfoModule,
    ],
    providers: providersList,
    bootstrap: [AppComponent],
})
export class AppModule {
}
