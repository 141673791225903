<div class="card">
    <avl-progress-bar-element-border
        class="close-button--top-right"
        borderWidth="2px"
        color="#6f90d4"
        [isLoading]="true"
        [duration]="closeInMs + 'ms'"
    >
        <button
            class="clear-btn-styles close-button"
            (click)="close()"
        >
            <mat-icon
                class="close-button__icon"
                svgIcon="close"
            ></mat-icon>
        </button>
    </avl-progress-bar-element-border>
    <mat-icon
        class="icon"
        svgIcon="check-mark-with-bg"
    ></mat-icon>
    <div class="title-description">
        <p class="title">{{ title }}</p>
        <p
            *ngIf="description"
            class="description"
        >{{ description }}</p>
    </div>
    <button
        class="avl-btn avl-btn--dark action-button"
        (click)="close()"
    >
        Done
    </button>
</div>
