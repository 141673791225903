import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CHOOSE_DOCUMENT_TYPE_FOR_REPORT, NO_TITLE_OR_SCOTLIS_DOCUMENTS_IN_PROJECT, REPORT_DOWNLOAD_SUCCESSFUL, SOMETHING_GONE_WRONG } from '@constants';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DoneDialogComponent } from '@shared/components/dialogs/done-dialog/done-dialog.component';
import { DownloadReportDialogComponent } from '../components/dialogs/download-report-dialog/download-report-dialog.component';
import { ReportGenerationDialogComponent } from '../components/dialogs/report-generation-dialog/report-generation-dialog.component';
import { DocumentsQuery } from 'app/titles/store';
import { BehaviorSubject } from 'rxjs';
import { OnboardingManageService } from '../../onboarding/services';
import { HttpQueryParamsService } from '@services';

@Injectable()
export class ReportGenerationHandlerService {
    private readonly docTypeForReport = new BehaviorSubject<(o: { key: string }) => boolean>(() => true);
    private readonly uniqueDocTypes = new BehaviorSubject<string[]>([]);

    constructor(
        private readonly onboardingService: OnboardingManageService,
        private readonly documentsQuery: DocumentsQuery,
        private readonly dialog: MatDialog,
        private readonly queryParamsService: HttpQueryParamsService,
    ) {
    }

    public openGenerateReportDialog(): void {
        if (this.uniqueDocTypes.getValue().length < -1) {
            // this is changed from .length > 1 to -1 so this could is never reached, and no dialog is shown.
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                panelClass: 'confirm-dialog',
                data: CHOOSE_DOCUMENT_TYPE_FOR_REPORT,
            });

            dialogRef.afterClosed().subscribe((isConfirm) => {
                if (isConfirm) {
                    this.setDocTypeForReport((o: { key: string }) => o.key.startsWith('scottish-'));
                } else {
                    this.setDocTypeForReport((o: { key: string }) => !o.key.startsWith('scottish-'));
                }

                this.internalOpenGenerateReportDialog();
            });
        } else {
            this.setDocTypeForReport(() => true);
            this.internalOpenGenerateReportDialog();
        }
    }

    public openDoneDialog(details: { title?: string; message: string }, withAfterClose: boolean | undefined = false): void {
        const dialogRef = this.dialog.open(DoneDialogComponent, {
            panelClass: ['report-dialog', 'border-radius-8px'],
            width: '440px',
            disableClose: true,
            data: {
                title: details.title,
                description: details.message,
            },
        });

        if (withAfterClose) {
            dialogRef.afterClosed().subscribe(() => this.openSelectReportDialog());
        }
    }

    public openAlertDialog(withAfterClose?: boolean, errorData?: { title: string; message: string }): void {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            data: errorData,
        });

        if (withAfterClose) {
            dialogRef.afterClosed().subscribe(() => this.openSelectReportDialog());
        }
    }

    public setUniqueDocTypes(array: string[]): void {
        this.uniqueDocTypes.next(array);
    }

    public setDocTypeForReport(fun: (o: { key: string }) => boolean): void {
        this.docTypeForReport.next(fun);
    }

    private internalOpenGenerateReportDialog(): void {
        this.onboardingService.closeActiveOverlay();
        this.onboardingService.isDisabledNextButton$.next(true);

        const dialogRef = this.dialog.open(ReportGenerationDialogComponent, {
            panelClass: 'report-dialog',
            width: '370px',
            disableClose: true,
            data: { numOfDocuments: this.documentsQuery.getCount() },
        });

        dialogRef.afterClosed().subscribe((result: string) => {
            switch (result) {
                case 'success':
                    this.openSelectReportDialog();
                    break;
                case 'error':
                    this.openAlertDialog(false, SOMETHING_GONE_WRONG);
                    break;
                case 'not-found':
                    this.openAlertDialog(false, NO_TITLE_OR_SCOTLIS_DOCUMENTS_IN_PROJECT);
                    break;
            }
        });
    }

    private openSelectReportDialog(): void {
        this.onboardingService.closeActiveOverlay();
        this.onboardingService.isDisabledNextButton$.next(true);
        this.queryParamsService.addTemporaryParamToUrl('keyState', 'report');

        const dialogRef = this.dialog.open(DownloadReportDialogComponent, {
            panelClass: ['report-dialog', 'download-report'],
            disableClose: this.onboardingService.isActive,
            data: { filter: this.docTypeForReport.getValue() },
        });
        dialogRef.afterClosed()
            .subscribe((result: string) => {
                switch (result) {
                    case 'success':
                        this.onboardingService.isActive
                            ? this.onboardingService.waitingForReportDownload()
                            : this.openDoneDialog(REPORT_DOWNLOAD_SUCCESSFUL, true);
                        break;
                    case 'error':
                        this.openAlertDialog(true);
                        break;
                    case '':
                    case undefined:
                        this.queryParamsService.addTemporaryParamToUrl('keyState', 'report-generated');
                        break;
                }
            });
    }
}
