import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionsComponent } from './components/versions/versions.component';
import { RouterModule } from '@angular/router';
import { systemInfoRoutes } from './system-info.routing';


@NgModule({
    declarations: [
        VersionsComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(systemInfoRoutes),
    ],
})
export class SystemInfoModule {
}
