import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FileService } from '@services';
import { PurchaseService } from '../../services';
import { PurchasedFilesQuery, PurchasedSelectedFilesQuery } from '../../store';

import { IFilesForDownload, IPurchasedFile } from '../../types';

import { DoneDialogComponent } from '@shared/components/dialogs/done-dialog/done-dialog.component';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';
import { PurchasedFilesTableComponent } from '../purchased-files-table/purchased-files-table.component';

import { Observable, Subject, Subscription } from 'rxjs';
import { FILE_NOT_FOUND, SOMETHING_GONE_WRONG } from '@constants';
import { ICollectionPageEvent, IPagination } from '@core/types';

@Component({
    selector: 'avl-purchased-page',
    templateUrl: './purchased-page.component.html',
    styleUrls: ['./purchased-page.component.scss'],
})
export class PurchasedPageComponent implements OnInit, OnDestroy {
    @ViewChild(PurchasedFilesTableComponent, { static: false })
    public purchasedTable: PurchasedFilesTableComponent;

    public purchaseFiles$: Observable<IPurchasedFile[]>;
    public filesForDownload$: Observable<IFilesForDownload[]>;
    public loading$: Observable<boolean>;
    public downloadAction$: Subscription;
    public pagination: IPagination = { totalCount: 0 };
    public resetSelection$ = new Subject<boolean>();

    constructor(
        private readonly dialog: MatDialog,
        private readonly purchasedQuery: PurchasedFilesQuery,
        private readonly selectedFilesQuery: PurchasedSelectedFilesQuery,
        private readonly purchaseService: PurchaseService,
        private readonly fileService: FileService,
    ) {
    }

    public ngOnInit(): void {
        this.purchaseFiles$ = this.purchasedQuery.selectAll();
        this.filesForDownload$ = this.selectedFilesQuery.selectAll();
        this.loading$ = this.purchasedQuery.getLoading();
    }

    public ngOnDestroy(): void {
        this.purchaseService.resetSelectedFiles();
        this.purchaseService.resetState();
    }

    public onFilesLoad(): void {
        this.purchaseService.getPurchasedFilesList(this.purchasedQuery.getValue().ui)
            .subscribe(() => {
                this.pagination = this.purchasedQuery.getPagination();
            });
    }

    public onSelectedFiles(selectedFiles: IPurchasedFile[]): void {
        this.purchaseService.selectFiles(selectedFiles);
    }

    public onPageChanged(page: ICollectionPageEvent): void {
        this.purchaseService.updatePaginationUI(page);
        this.onFilesLoad();
    }

    public onSearch(value: string): void {
        this.purchaseService.updateSearchUI(value);
        this.purchasedTable.resetToFirstPage();
        this.onFilesLoad();
    }

    public downloadFiles(): void {
        const filesForDownload = this.selectedFilesQuery.getAll();
        this.downloadAction$ = this.purchaseService.downloadPurchasedFiles(filesForDownload)
            .subscribe({
                next: (response) => {
                    this.fileService.download(response);
                    this.resetSelection$.next(true);
                    this.purchaseService.resetSelectedFiles();
                    this.openDoneDialog();
                },
                error: (error) => {
                    if (error?.status === 404) {
                        return this.openAlertDialog(FILE_NOT_FOUND);
                    }

                    this.openAlertDialog(SOMETHING_GONE_WRONG);
                },
            });
    }

    public openDoneDialog(): void {
        this.dialog.open(DoneDialogComponent, {
            panelClass: ['report-dialog', 'border-radius-8px'],
            width: '440px',
            disableClose: true,
            data: { title: 'Purchased files downloaded' },
        });
    }

    private openAlertDialog(error: { title: string; message: string }): void {
        this.dialog.open(AlertDialogComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            data: error,
        });
    }
}
