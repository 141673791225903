<div class="card">
    <avl-progress-bar-element-border
        class="close-button--top-right"
        borderWidth="2px"
        color="#6f90d4"
        [isDisabled]="isClosingTimerStopped"
        [duration]="closeInMs + 'ms'"
    >
        <button
            class="clear-btn-styles close-button"
            (click)="close()"
        >
            <mat-icon
                class="close-button__icon"
                svgIcon="close"
            ></mat-icon>
        </button>
    </avl-progress-bar-element-border>
    <mat-icon
        class="icon"
        [svgIcon]="isErrorModal ? 'error-circle-with-bg' : 'warning-circle-with-bg'"
    ></mat-icon>
    <div class="title-description">
        <p class="title">{{ title }}</p>
        <p
            *ngIf="description"
            class="description"
        >{{ description }}</p>
    </div>

    <div
        *ngIf="!isErrorModal"
        class="status-panels"
    >
        <avl-status-panel
            class="status-panel"
            borderColor="#6ED972"
            backgroundColor="#EDFFEB"
            title="Successfully purchased"
            svgIcon="green-check"
            [items]="successItems"
            (click)="stopTimer()"
        ></avl-status-panel>

        <avl-status-panel
            class="status-panel"
            borderColor="#FEB979"
            backgroundColor="#FFF5EB"
            title="Failed to purchase"
            svgIcon="exclamation-triangle-outline"
            [items]="failedItems"
            (click)="stopTimer()"
        ></avl-status-panel>
    </div>

    <avl-status-panel
        *ngIf="isErrorModal"
        class="status-panel"
        borderColor="#EA7878"
        backgroundColor="#FFEBEB"
        title="Failed to purchase"
        svgIcon="close-red"
        [items]="failedItems"
        (click)="stopTimer()"
    ></avl-status-panel>

    <button
        class="avl-btn avl-btn--dark action-button"
        (click)="close()"
    >
        Close
    </button>
</div>
