import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggerService, ProfileService } from '@services';
import { OnboardingManageService } from '../services';

@Injectable()
export class InitiateOnboardingResolver implements Resolve<boolean> {
    constructor(
        private readonly profileService: ProfileService,
        private readonly log: LoggerService,
        private readonly onboarding: OnboardingManageService,
    ) {
    }

    public resolve(): Observable<boolean> {
        return this.profileService.shouldShowOnboarding()
            .pipe(
                map((isOnboardingEnabled) => {
                    this.log.info('shouldShowOnboarding.subscribe', isOnboardingEnabled);

                    if (isOnboardingEnabled) {
                        this.onboarding.start();
                        this.profileService.onboardingShown().subscribe();
                    }

                    return isOnboardingEnabled;
                })
            );
    }
}
