<ng-container #statusPanel>
    <mat-expansion-panel
        class="panel"
        [expanded]="isExpanded"
    >
        <mat-expansion-panel-header>
            <div class="panel__header-content">
                <mat-icon
                    class="panel__icon"
                    [svgIcon]="svgIcon"
                ></mat-icon>
                <span class="panel__header-text">{{ title }}</span>
            </div>
        </mat-expansion-panel-header>
        <div class="panel__details scroll-container--thin-scroll">
            <div
                *ngFor="let item of items"
                class="panel__item"
            >
                <div class="panel__details-text panel__code">
                    <span class="panel__dot">•</span>
                    {{ item.title }}
                </div>
                <div
                    *ngIf="item.description"
                    class="panel__details-text panel__message"
                >
                    {{ item.description }}
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</ng-container>
