import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { timer } from 'rxjs';

export type PartialSuccessActionDialogData = {
    title: string;
    description?: string;
    successItems: PartialSuccessActionDialogItemDetails[];
    failedItems: PartialSuccessActionDialogItemDetails[];
}

export type PartialSuccessActionDialogItemDetails = {
    title: string;
    description?: string;
}

@Component({
    selector: 'avl-purchase-result-details-dialog',
    templateUrl: './purchase-result-details-dialog.component.html',
    styleUrls: ['./purchase-result-details-dialog.component.scss'],
})
export class PurchaseResultDetailsDialogComponent implements OnInit {
    public readonly closeInMs = 8_000;
    public successItems: PartialSuccessActionDialogItemDetails[];
    public failedItems: PartialSuccessActionDialogItemDetails[];
    public title: string;
    public description: string;
    public isClosingTimerStopped = false;
    public isErrorModal = false;


    constructor(
        private readonly dialogRef: MatDialogRef<PurchaseResultDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        private readonly data: PartialSuccessActionDialogData,
    ) {
        this.title = this.data.title;
        this.description = this.data.description || '';
        this.successItems = this.data.successItems || [];
        this.failedItems = this.data.failedItems || [];
        this.isErrorModal = !this.successItems.length;
    }

    public ngOnInit(): void {
        timer(this.closeInMs)
            .subscribe(() => {
                if (!this.isClosingTimerStopped) {
                    this.close();
                }
            });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public stopTimer(): void {
        this.isClosingTimerStopped = true;
    }
}
