import { Component } from '@angular/core';
import packageInfo from '../../../../../package.json';

@Component({
    selector: 'avl-versions',
    templateUrl: './versions.component.html',
    styleUrls: ['./versions.component.scss'],
})
export class VersionsComponent {
    public readonly appVersion = packageInfo.version;
    public readonly headers = ['Entity Name', 'Version'];
    public readonly data = [
        {
            [this.headers[0]]: 'App version',
            [this.headers[1]]: this.appVersion,
        },
    ];
}
