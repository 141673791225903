import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LandRegistrySearchSource } from '../enums/land-registry-search-source.enum';
import { PurchasedTitleDetails } from '../types/purchased-title-details.type';
import { ITileNumberForPurchase, LandRegistryApi, TitleInfoToMap } from '../types';

@Injectable()
export class HmlrApi implements LandRegistryApi {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public getContentByLocation(url: string): Observable<HttpResponse<TitleInfoToMap[]>> {
        return this.http.get<TitleInfoToMap[]>(url, { observe: 'response' });
    }

    public getSearchResultsExcel(url: string): Observable<HttpResponse<ArrayBuffer>> {
        return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
    }

    public getContentLocation(folderId: string, kind: string, query: string, source: LandRegistrySearchSource = LandRegistrySearchSource.default): Observable<string> {
        const url = `/api/hmlr/search/${folderId}`;

        return this.http.post(url, { kind, query, source }, { observe: 'response' })
            .pipe(
                map((response) => response.headers.get('Content-Location') || ''),
            );
    }

    public refresh(folderId: string): Observable<string> {
        return this.http.post(`/api/hmlr/refresh/${folderId}`, null, { observe: 'response' })
            .pipe(
                map((response: HttpResponse<null>) => response.headers.get('Content-Location')),
            );
    }

    public purchase(folderId: string, titleNumbers: ITileNumberForPurchase[]): Observable<string> {
        return this.http.post(`/api/hmlr/purchase/${folderId}`, titleNumbers, { observe: 'response' })
            .pipe(
                map((response) => response.headers.get('Content-Location') || ''),
            );
    }

    public getPurchaseStatus(url: string): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        return this.http.get<PurchasedTitleDetails[] | ''>(url, { observe: 'response' })
            .pipe(
                map((response) =>
                    new HttpResponse({
                        ...response,
                        body: response.body || [],
                    }),
                ),
            );
    }
}
