import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { IFilesForDownload, INotificationCounter, IPurchasedFile } from '../types';

@Injectable()
export class PurchaseApi {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public getPurchasedFilesList(params: HttpParams): Observable<HttpResponse<IPurchasedFile[]>> {
        return this.http.get<IPurchasedFile[]>('/api/titles/purchased', { params, observe: 'response' });
    }

    public getPurchasedFiles(files: IFilesForDownload[]): Observable<HttpResponse<ArrayBuffer>> {
        return this.http.post('/api/titles/purchased', files, { responseType: 'arraybuffer', observe: 'response' });
    }

    public getActivePurchases(): Observable<INotificationCounter> {
        return this.http.get<INotificationCounter>('/api/titles/purchased/active');
    }
}
