<div class="table-container">
    <table class="table table-striped">
        <thead>
            <tr>
                <th *ngFor="let header of headers">{{ header }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of data">
                <td *ngFor="let col of headers">{{ row[col] }}</td>
            </tr>
        </tbody>
    </table>

    <div
        *ngIf="data.length === 0"
        class="no-data"
    >
        No data available.
    </div>
</div>
