import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { FileUploaderModule } from '../blocks/file-uploader';

import { AlertOkDialogComponent } from './components/dialogs/alert-ok-dialog/alert-ok-dialog.component';
import { ErrorAlertComponent } from './components/alerts/error-alert/error-alert.component';
import { AlertDialogComponent } from './components/dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { DoneDialogComponent } from './components/dialogs/done-dialog/done-dialog.component';
import { FilesUploadedSnackbarComponent } from './components/files-uploaded-snackbar/files-uploaded-snackbar.component';
import { HeaderComponent } from './components/header/header.component';
import { MultipleErrorAlertComponent } from './components/multiple-error-alert/multiple-error-alert.component';
import { SearchComponent } from './components/search/search.component';
import { TableLoadingPlaceholderComponent } from './components/table-loading-placeholder/table-loading-placeholder.component';
import { TableNoDataDisclaimerComponent } from './components/table-no-data-disclaimer/table-no-data-disclaimer.component';

import { ElevateDirective } from './directives/elevate.directive';
import { PulseButtonDirective } from './directives/pulse-button.directive';

import { BypassSecurityTrustUrl, DocumentFormatPipe } from './pipes';
import { DocumentTypePipe } from './pipes/document-type.pipe';
import { PurchaseErrorAlertComponent } from '@shared/components/alerts/purchase-error-alert/purchase-error-alert.component';
import { ScheduleErrorAlertComponent } from './components/alerts/schedule-error-alert/schedule-error-alert.component';
import { SnackbarInfoMessageComponent } from './components/snackbar-info-message/snackbar-info-message.component';
import { FolderDetailsDialogComponent } from '@shared/components/dialogs/folder-details-dialog/folder-details-dialog.component';
import { UploadDropAreaComponent } from '@shared/components/upload-drop-area/upload-drop-area.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ElementOverDirective } from './directives/element-over.directive';
import { OtherToolsComponent } from './components/other-tools/other-tools.component';
import { InfoSnackbarComponent } from '@shared/components/info-snackbar/info-snackbar.component';
import { ExternalRedirectComponent } from './components/external-redirect/external-redirect.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { HighlightDirective } from '@shared/directives/highlight.directive';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { NotFoundPageComponent } from '@shared/components/not-found-page/not-found-page.component';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { FixedToggleComponent } from '@shared/components/fixed-toggle/fixed-toggle.component';
import { NoSubscriptionsComponent } from './components/no-subscriptions/no-subscriptions.component';
import { ProgressBarElementBorderComponent } from './components/progress-bar-element-border/progress-bar-element-border.component';
import { StatusPanelComponent } from './components/status-panel/status-panel.component';

@NgModule({
    declarations: [
        HeaderComponent,
        DoneDialogComponent,
        AlertDialogComponent,
        AlertOkDialogComponent,
        ConfirmDialogComponent,
        ErrorAlertComponent,
        PurchaseErrorAlertComponent,
        BypassSecurityTrustUrl,
        DocumentFormatPipe,
        DocumentTypePipe,
        ElevateDirective,
        PulseButtonDirective,
        SearchComponent,
        TableNoDataDisclaimerComponent,
        FilesUploadedSnackbarComponent,
        MultipleErrorAlertComponent,
        TableLoadingPlaceholderComponent,
        ScheduleErrorAlertComponent,
        SnackbarInfoMessageComponent,
        ScheduleErrorAlertComponent,
        FolderDetailsDialogComponent,
        UploadDropAreaComponent,
        ButtonComponent,
        ElementOverDirective,
        OtherToolsComponent,
        InfoSnackbarComponent,
        ExternalRedirectComponent,
        HighlightDirective,
        SpinnerComponent,
        PdfViewerComponent,
        FixedToggleComponent,
        NotFoundPageComponent,
        DocumentViewerComponent,
        NoSubscriptionsComponent,
        ProgressBarElementBorderComponent,
        StatusPanelComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatSortModule,
        MatDialogModule,
        HttpClientModule,
        FileUploaderModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatButtonModule,
        MatMenuModule,
        BrowserAnimationsModule,
        Angular2PromiseButtonModule.forRoot({
            spinnerTpl: '<span class="avl-btn-spinner"></span>',
            btnLoadingClass: 'is-loading',
        }),
    ],
    exports: [
        ErrorAlertComponent,
        PurchaseErrorAlertComponent,
        HeaderComponent,
        MatIconModule,
        MatDialogModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        RouterModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatTableModule,
        MatSortModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
        MatRippleModule,
        MatPaginatorModule,
        Angular2PromiseButtonModule,
        ElevateDirective,
        FileUploaderModule,
        PulseButtonDirective,
        DocumentFormatPipe,
        DocumentTypePipe,
        SearchComponent,
        TableNoDataDisclaimerComponent,
        TableLoadingPlaceholderComponent,
        FilesUploadedSnackbarComponent,
        MultipleErrorAlertComponent,
        ScheduleErrorAlertComponent,
        MatCheckboxModule,
        FolderDetailsDialogComponent,
        UploadDropAreaComponent,
        ButtonComponent,
        ElementOverDirective,
        OtherToolsComponent,
        InfoSnackbarComponent,
        SnackbarInfoMessageComponent,
        HighlightDirective,
        SpinnerComponent,
        FixedToggleComponent,
        ProgressBarElementBorderComponent,
        StatusPanelComponent,
    ],
})
export class SharedModule {
}
