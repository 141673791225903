import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';

import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { OnboardingOverlayComponent } from './components/onboarding-overlay/onboarding-overlay.component';

import { SharedModule } from '@shared/shared.module';
import { CharacterViewComponent } from './components/character-view/character-view.component';
import { MedalComponent } from './components/medal/medal.component';
import { InitiateOnboardingResolver } from './resolvers/initiate-onboarding.resolver';

export function playerFactory(): LottiePlayer {
    return player;
}

@NgModule({
    declarations: [
        OnboardingOverlayComponent,
        SplashScreenComponent,
        CharacterViewComponent,
        MedalComponent,
    ],
    providers: [
        InitiateOnboardingResolver,
    ],
    imports: [
        CommonModule,
        SharedModule,
        LottieModule.forRoot({ player: playerFactory }),
    ],
})
export class OnboardingModule {
}
