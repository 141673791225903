import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { LoggerService } from '@services';
import { AuthService, RoutingService } from '@auth/services';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
    providedIn: 'root',
})
export class RedirectGuard implements CanActivate {

    constructor(
        private readonly authService: AuthService,
        private readonly routingService: RoutingService,
        private readonly log: LoggerService) {
    }

    public canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return fromPromise(this.authService.firebaseAuth.getRedirectResult())
            .pipe(
                catchError((err) => {
                    this.log.info('getRedirectResult error', err);
                    return of(null);
                }),
                switchMap((result) => {
                    if (result && result.user) {
                        if (result.additionalUserInfo) {
                            this.authService.updateUserInformation(result);
                        }

                        return this.routingService.parseHomeUrl(next);
                    }

                    return of(true);
                }),
            );
    }

}
