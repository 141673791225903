import { Injectable } from '@angular/core';
import { BookmarksApi } from '../api';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotifyCountersStore } from '../store';
import { INotificationCounter } from '../types';
import { PurchaseService } from './purchase.service';

@Injectable()
export class NotifyCountersService {

    constructor(
        private readonly bookmarksApi: BookmarksApi,
        private readonly purchaseService: PurchaseService,
        private readonly notifyCountersStore: NotifyCountersStore,
    ) {
    }

    public getCounters(): Observable<[INotificationCounter, INotificationCounter]> {
        return forkJoin([
            this.purchaseService.getActivePurchases(),
            this.bookmarksApi.getActiveBookmarks(),
        ])
            .pipe(
                tap((response) => {
                    this.notifyCountersStore.update({
                        purchased: response[0],
                        bookmarks: response[1],
                    });
                }),
            );
    }
}
