import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

export type StatusPanelItem = {
    title: string;
    description?: string;
}

@Component({
    selector: 'avl-status-panel',
    templateUrl: './status-panel.component.html',
    styleUrls: ['./status-panel.component.scss'],
})
export class StatusPanelComponent implements AfterViewInit {
    @ViewChild('statusPanel')
    public panel!: ElementRef;

    @Input()
    public items: StatusPanelItem[];

    @Input()
    public isExpanded = false;

    @Input()
    public title: string;

    @Input()
    public borderColor: string;

    @Input()
    public backgroundColor: string;

    @Input()
    public svgIcon: string;


    public ngAfterViewInit(): void {
        setTimeout(() => this.updateCssVars());
    }

    private updateCssVars(): void {
        const root = this.panel?.nativeElement?.previousElementSibling;

        if (!root) {
            return;
        }

        root.style.setProperty('--border-color', this.borderColor);
        root.style.setProperty('--background-color', this.backgroundColor);
    }
}
