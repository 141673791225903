import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'avl-progress-bar-element-border',
    templateUrl: './progress-bar-element-border.component.html',
    styleUrls: ['./progress-bar-element-border.component.scss'],
})
export class ProgressBarElementBorderComponent implements OnChanges {
    @Input()
    public duration = '3s';

    @Input()
    public color = 'black';

    @Input()
    public borderWidth = '1px';

    @Input()
    public isLoading = true;

    @Input()
    public isDisabled = false;

    @ViewChild('contentSlot')
    public contentSlotRef!: ElementRef;

    public borderRadius: number;


    public ngOnChanges(): void {
        setTimeout(() => this.updateCssVars());
    }

    private updateCssVars(): void {
        const slot = this.contentSlotRef.nativeElement;
        const projectedContent = slot.querySelector(':scope > *');

        if (projectedContent) {
            const computedStyles = window.getComputedStyle(projectedContent);
            const borderRadius = parseFloat(computedStyles.borderRadius) || 0;

            this.borderRadius = borderRadius + parseInt(this.borderWidth);
            this.contentSlotRef.nativeElement.style.setProperty('--duration', this.duration);
            this.contentSlotRef.nativeElement.style.setProperty('--border-width', this.borderWidth);
            this.contentSlotRef.nativeElement.style.setProperty('--color', this.color);
            this.contentSlotRef.nativeElement.style.setProperty('--play-state', this.isLoading && !this.isDisabled ? 'running' : 'paused');
        }
    }
}
