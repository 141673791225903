import { IConfirmDialogData } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

export const REMOVE_LEASE_CONFIRM_DATA: IConfirmDialogData = {
    color: 'bright-red',
    icon: 'remove-icon',
    title: 'Are you sure you want to remove this lease?',
    unConfirmButton: 'Cancel',
    confirmButton: 'Remove',
};

export const FILED_COPIES_ANALYSIS_LIMIT_IS_OVER: IConfirmDialogData = {
    color: 'light-yellow',
    icon: 'remove-icon',
    title: 'Oops! You can’t analyse over 100 filed copies in one go. Either remove some titles from your '
        + 'matter, or click below to contact us and we’ll run this for you 😊',
    unConfirmButton: 'Cancel',
    confirmButton: 'Chat to us',
};

export const FILED_COPIES_ANALYSIS_ENGAGEMENT: IConfirmDialogData = {
    color: 'green',
    class: 'buttons_small-side-paddings',
    icon: 'land-registry-analysis',
    title: 'You’ve just purchased some filed copies! Would you like to analyse these also and include summaries in your reports?',
    unConfirmButton: 'No thanks',
    confirmButton: 'Sounds great!',
};

export const REMOVE_UPLOADED_TITLE_DOCUMENT_CONFIRM_DATA: IConfirmDialogData = {
    color: 'red',
    icon: 'remove-icon',
    title: 'Are you sure you want to remove this uploaded title document?',
    unConfirmButton: 'Cancel',
    confirmButton: 'Remove',
};

export const REMOVE_PURCHASED_TITLE_DOCUMENT_CONFIRM_DATA: IConfirmDialogData = {
    color: 'red',
    icon: 'remove-icon',
    title: `Are you sure you want to remove this purchased title document?
          It will still be available in ‘Purchased Files’ from the main menu`,
    unConfirmButton: 'Cancel',
    confirmButton: 'Remove',
};

export const REMOVE_NOTICE_CONFIRM_DATA: IConfirmDialogData = {
    color: 'red',
    icon: 'remove-icon',
    title: 'Are you sure you want to remove this document?',
    unConfirmButton: 'Cancel',
    confirmButton: 'Remove',
};

export const CREATE_NEW_FOLDER_CONFIRM_DATA: IConfirmDialogData = {
    color: 'blue',
    icon: 'create-icon',
    title: 'Are you sure you want to start a new project?',
    subtitle: 'You can access this project again from the main menu.',
    unConfirmButton: 'Cancel',
    confirmButton: 'New Project',
};

export const PURCHASE_A_LOT_OF_TITLES: IConfirmDialogData = {
    color: 'green',
    icon: 'pound-icon',
    title: 'Are you sure you want to purchase these files?',
    subtitle: 'You will incur a cost of £XXX on your client/matter file.',
    unConfirmButton: 'Cancel',
    confirmButton: 'Purchase',
};

export const CREATE_NEW_NOTICE_CONFIRM_DATA: IConfirmDialogData = {
    color: 'notice-yellow',
    icon: 'create-icon',
    title: 'Are you sure you want to start a new project?',
    subtitle: 'You can access this project again from the main menu.',
    unConfirmButton: 'Cancel',
    confirmButton: 'New Project',
};

export const TUTORIAL_VIEW_CONFIRM_DATA: IConfirmDialogData = {
    color: 'blue',
    icon: 'create-icon',
    title: 'Are you sure you want to view the tutorial again?',
    subtitle: 'You can access this project again from the main menu.',
    unConfirmButton: 'Cancel',
    confirmButton: 'Yes',
};

export const CHOOSE_DOCUMENT_TYPE_FOR_REPORT: IConfirmDialogData = {
    color: 'blue',
    icon: 'remove-icon',
    title: 'Which type of reports would you like to generate?',
    subtitle: 'You have uploaded both English and Scottish reports.',
    unConfirmButton: 'English',
    confirmButton: 'Scottish',
};

export const REFRESH_CONFIRM_DATA: IConfirmDialogData = {
    color: 'yellow',
    icon: 'remove-icon',
    title: `Some of your title registers are dated in the past.
          Do you want to refresh these with up-to-date documents from HM Land Registry?`,
    unConfirmButton: 'Cancel',
    confirmButton: 'Refresh',
};

export const LONG_PROCESSING_REFRESH_CONFIG: MatDialogConfig = {
    panelClass: 'report-dialog',
    width: '400px',
    data: {
        title: 'Oops! You\'ve taken too long',
        message: 'The processing is taking longer than normal, please refresh the tab or contact the support team to resolve the issue.',
    },
};

export const TOO_MANY_FILES_TO_PURCHASE: MatDialogConfig = {
    panelClass: 'report-dialog',
    width: '400px',
    data: {
        title: 'Oops, you\'ve hit your limit',
        message: `You can purchase a maximum of 1,000 documents in one go.
              Please place multiple orders if you require over 1,000 in your project, or contact our support team.`,
        button: 'OK',
    },
};

export const NO_VALID_DOCUMENT_TO_PROCEED: MatDialogConfig = {
    panelClass: 'report-dialog',
    width: '400px',
    data: {
        title: 'Oops, no valid documents are uploaded or purchased',
        message: 'Please upload or purchase title register(s) to be able to generate your reports',
        button: 'OK',
    },
};

export const NO_TITLES_ONLY_PLANS: MatDialogConfig = {
    panelClass: 'report-dialog',
    width: '400px',
    data: {
        title: 'Oops.',
        message: 'Your title plan(s) don\'t relate to a title register(s) in your project. Please upload or purchase the corresponding title register(s) to be '
            + 'able to generate your reports.',
        button: 'OK',
    },
};

export const MISMATCH_TITLES_AND_PLANS: IConfirmDialogData = {
    color: 'yellow',
    icon: 'remove-icon',
    title: 'Your title registers and plans don\'t appear to correspond.',
    subtitle: 'Any title plans which do not relate to a title register in your project will be excluded from your reports.',
    unConfirmButton: 'Cancel',
    confirmButton: 'Proceed',
};

export const NO_SUBSCRIPTION_TO_APP_CONFIG: MatDialogConfig = {
    panelClass: 'report-dialog',
    width: '400px',
    data: {
        title: 'No subscription',
        message: 'You are not subscribed to this app and can’t access it',
        button: 'OK',
    },
};

export const NO_TITLE_OR_SCOTLIS_DOCUMENTS_IN_PROJECT = {
    title: 'Oops!',
    message: 'There is no Title Register or ScotLIS document in the project to generate the reports.',
};

export const REPORT_DOWNLOAD_SUCCESSFUL = {
    title: 'Download Successful',
    message: 'Report downloaded successfully',
};

export const ROS_IS_UNAVAILABLE = {
    title: 'Oops! ROS is unavailable',
    message: 'The service is unavailable. Try again later.',
};

export const ROS_REPEAT_PURCHASING = {
    title: 'Repeat purchasing',
    message: 'One or more titles have already been purchased.',
};

export const SOMETHING_GONE_WRONG = {
    title: 'Oops!',
    message: 'Something\'s gone wrong. Please try again, or contact our support live chat.',
};

export const UNSUPPORTED_FILE_TYPE = {
    title: 'Oops! {document_name} couldn\'t be uploaded.',
    message: 'This document is not a supported document type. Please only upload {file_type} in PDF format.',
};

export const FILE_NOT_FOUND = {
    title: 'Oops!',
    message: 'The file is not found. Please try again, or contact our support live chat.',
};

export const UNABLE_COMMUNICATE_WITH_SERVER = {
    title: 'Oops!',
    message: 'Unable to communicate with the server, please refresh the page and try again or contact the support team.',
};

export const TOO_MANY_TITLE_NUMBERS = {
    title: 'Oops, that\'s a few too many!',
    message: 'You can only search for 200 title numbers in one go. Please remove a few and try your search again.',
    button: 'OK',
};

export const TRY_AGAIN_BETWEEN_6_30_AND_23_OCLOCK
    = 'You can only obtain official copies from HM Land Registry during their working hours. Please try again between 6:30 and 23:00';

export const CLEAR_YOUR_TEMPORARY_FILES
    = 'Something\'s gone wrong. Please clear your temporary internet files and try again.';

export const ACCOUNT_NOT_AUTHORISED
    = 'Your account doesn\'t seem to be authorised to do this. Please try again or contact our support live chat.';

export const RUN_OUT_OF_CREDIT
    = 'You\'ve run out of credit. Please contact your head of innovation or LegalTech for more information.';

export const ANOTHER_TRANSACTION_IS_IN_PROGRESS
    = 'Another transaction of this project is in progress. Please wait until the other transaction is complete to avoid purchasing the same items twice.';

export const TITLE_PURCHASED_SUCCESSFULLY = {
    title: 'Purchase Successful',
    message: 'All selected documents have been successfully purchased',
};

export const TITLE_PURCHASE_PARTIAL_SUCCESS = {
    title: 'Purchase partially successful',
    message: 'Some titles were purchased successfully, while others failed:',
};

export const TITLE_PURCHASE_FAIL = {
    title: 'Purchase Failed',
    message: 'None of the selected titles were purchased.',
};

export const SECURITY_REASON_REAUTH_TOAST
    = 'For security reasons we need to reauthenticate you. Your documents are saved.';

export const HAVE_TAKEN_TOO_LONG_TOAST = 'You\'ve taken too long. ' + SECURITY_REASON_REAUTH_TOAST;

export const SOMETHING_WENT_WRONG_DURING_FILES_UPLOADING = {
    title: 'Oops!',
    message: 'Something went wrong. Some files weren\'t uploaded',
};

export const SOMETHING_WENT_GONE_REFRESH_PAGE = {
    title: 'Oops!',
    message: 'Something went wrong. Try to refresh the page',
};
