import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { timer } from 'rxjs';

export type DoneDialogData = {
    title: string;
    description?: string;
}

@Component({
    selector: 'avl-done-dialog',
    templateUrl: './done-dialog.component.html',
    styleUrls: ['./done-dialog.component.scss'],
})
export class DoneDialogComponent implements OnInit {
    public readonly closeInMs = 5000;
    public title: string;
    public description: string;


    constructor(
        private readonly dialogRef: MatDialogRef<DoneDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        private readonly data: DoneDialogData,
    ) {
        this.title = this.data.title;
        this.description = this.data.description || '';
    }

    public ngOnInit(): void {
        timer(this.closeInMs).subscribe(() => {
            this.close();
        });
    }

    public close(): void {
        this.dialogRef.close();
    }
}
